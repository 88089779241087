export default {
    creamDark: "#F1E2B7",
    creamLight: "#F8F0D8",
    pistachioDark: "#E1D78A",
    pistachioLight: "#F0EABB",
    orangeDark: "#FB8F35",
    orangeLight: "#FDBF74",
    pumpkinDark: "#CC3B06",
    pumpkinLight: "#E47B28",
    redDark: "#E62706",
    redLight: "#F2652C",
    oxbloodDark: "#A00706",
    oxbloodLight: "#CA2E28",
    mossDark: "#7B7743",
    mossLight: "#B1AF83",
    fernDark: "#8F8F1D",
    fernLight: "#BEBF57",
    pineDark: "#0A2110",
    pineLight: "#335C40",
    cypressDark: "#151B12",
    cypressLight: "#475443",
    sapphireDark: "#1D616B",
    sapphireLight: "#579DA6",
    spruceDark: "#002123",
    spruceLight: "#005A5F",
    firDark: "#020B08",
    firLight: "#17382E",
    barkDark: "#521306",
    barkLight: "#914823",
    aubergineDark: "#1E081B",
    aubergineLight: "#582E53"
}