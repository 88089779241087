export const BREAKPOINTS = {
  xs: 375,
  sm: 768,
  md: 960,
  lg: 1280,
  xl: 1920,
}

export default {
  mobile: `(min-width: ${BREAKPOINTS.xs}px)`,
  tablet: `(min-width: ${BREAKPOINTS.sm}px)`,
  laptop: `(min-width: ${BREAKPOINTS.md}px)`,
  desktop: `(min-width: ${BREAKPOINTS.lg}px)`,
  desktopL: `(min-width: ${BREAKPOINTS.xl}px)`,
}
